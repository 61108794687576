<template>
   <div>
      <img src="./imgs/banner_75.jpg" alt="" />
      <div class="features">
         <div style="height: 4em;"></div>
         <el-row>
            <el-col :span="14">
               <el-carousel arrow="never" indicator-position="outside">
                  <div  v-for="(item, key) in list"  :key="key">
                     <el-carousel-item v-if="item.cover != ''">
                        <div class="cover">
                           <img :src="item.cover" alt="">
                           <h2>
                              <a v-if="item.external_url != ''" :href="item.external_url" target="_blank">{{ item.title }}</a>
                              <router-link v-else :to="`/article?id=${item.id}`" >{{ item.title }}</router-link>
                           </h2>
                        </div>
                     </el-carousel-item>
                  </div>
               </el-carousel>
            </el-col>
            <el-col :span="10">
               <ul>
                  <div v-for="(item, key) in list" :key="key">
                     <li v-if="key >= 0 && key < 6">
                        <a v-if="item.external_url != ''" :href="item.external_url" target="_blank">{{ item.title }}</a>
                        <router-link v-else :to="`/article?id=${item.id}`" >{{ item.title }}</router-link>
                     </li>
                  </div>
               </ul>
            </el-col>
         </el-row>
         <div style="height: 2em;"></div>

         <div style="text-align: center;">
            <img style="width: 42%;" src="./imgs/title_75_01.png" alt="">
         </div>
         <div style="height: 2em;"></div>

         <el-row>
            <el-col :span="12">
               <ul>
                  <div v-for="(item, key) in list2" :key="key">
                     <li v-if="key >= 0 && key < 6">
                        <a v-if="item.external_url != ''" :href="item.external_url" target="_blank">{{ item.title }}</a>
                        <router-link v-else :to="`/article?id=${item.id}`" >{{ item.title }}</router-link>
                     </li>
                  </div>
               </ul>
            </el-col>
            <el-col :span="12">
               <ul>
                  <div v-for="(item, key) in list2" :key="key">
                     <li v-if="key >= 6 && key < 12">
                        <a v-if="item.external_url != ''" :href="item.external_url" target="_blank">{{ item.title }}</a>
                        <router-link v-else :to="`/article?id=${item.id}`" >{{ item.title }}</router-link>
                     </li>
                  </div>
               </ul>
            </el-col>
         </el-row>
         <div style="height: 4em;"></div>
      </div>
   </div>
</template>
<script>
import { http } from "@/http";
import { Col, Row, Dialog, Carousel, CarouselItem } from "element-ui";

export default {
   name: "PageFeature2",
   components: {
      [Col.name]: Col,
      [Row.name]: Row,
      [Dialog.name]: Dialog,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem
   },
   data() {
      return {
         total: 0,
         list: [],
         list2: [],
         loading: false
      };
   },
   created() {
      this.getData();
   },
   methods: {
      /**
       * 获取数据
       */
      getData() {
         this.loading = true;

         http
            .get("post?alias=national-day")
            .then((res) => {
               const { data, total } = res.data;
               this.total = total;
               this.list = data;
               this.getData2()
            })
            .finally(() => {
               this.loading = false;
            });
      },
      getData2() {
         this.loading = true;

         http
            .get("post?alias=huodong")
            .then((res) => {
               const { data } = res.data;
               this.list2 = data;
            })
            .finally(() => {
               this.loading = false;
            });
      },
   },
};
</script>

<style scoped>
.el-carousel__item h3 {
   color: #475669;
   font-size: 18px;
   opacity: 0.75;
   line-height: 300px;
   margin: 0;
}

.el-carousel__item:nth-child(2n) {
   background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
   background-color: #d3dce6;
}
.cover {
   h2 {
      margin: 0;
      padding: .4em 0;
      padding-left: 1em;
      font-size: 1em;
      background-color: rgba(0, 0, 0, 0.4);

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      a {
         color: #fff;
      }
   }
   }
.features {
   max-width: 1200px;
   margin: 0 auto;
   padding-top: 1em;

   a {
      color: #000;
      font-weight: 600;
      text-decoration: none;
   }

   ul {
      text-align: left;

      li {
         padding: .4em 0;
         line-height: 1.8em;
         border-bottom: 1px solid #eee;
      }
   }
}

.features h1 {
   color: #d1a861;
   padding: 20px 0;
   font-size: 28px;
   font-weight: 600;
}

.features p {
   text-align: center;
}

img {
   width: 100%;
}

ul {
   text-align: center;
}

ul img {
   width: 95%;
}
</style>
