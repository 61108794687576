<template>
   <div>
      <img src="@/assets/imgs/dabanner.jpg" alt="" />
      <div class="features">
         <el-row type="flex" :gutter="80" justify="center">
            <el-col :span="8">
               <img
                  src="@/assets/imgs/features2-1.png"
                  @click.prevent="plateVisibile = true"
               />
               <p>我为群众办实事</p>
            </el-col>
            <el-col :span="8">
               <img
                  src="@/assets/imgs/features2-2.png"
                  alt=""
                  @click.prevent="plateVisibile2 = true"
               />
               <p>我是党员，我在！</p>
            </el-col>
         </el-row>
      </div>
      <el-dialog
         :visible.sync="plateVisibile"
         :before-close="handleClose"
         width="800px"
         class="consult-plate__dialog"
         title="我为群众办事实"
         append-to-body
      >
         <div class="consult-plate">
            <div class="consult-plate__col">
               <div class="consult-plate__item">
                  <video
                     id="vedio1"
                     style="width:100%"
                     src="https://www.hntic.com/video/fae44da0200a6073a9aa2373c2c41715.mp4"
                     controls="controls"
                  >
                     您的浏览器不支持 video 标签。
                  </video>
               </div>
            </div>
         </div>
      </el-dialog>
      <el-dialog
         :visible.sync="plateVisibile2"
         :before-close="handleClose"
         width="800px"
         class="consult-plate__dialog"
         title="我是党员，我在！"
         append-to-body
      >
         <div class="consult-plate">
            <div class="consult-plate__col">
               <div class="consult-plate__item">
                  <video
                     id="vedio2"
                     style="width:100%"
                     src="https://www.hntic.com/video/a5e4569372012fb789e3ffe5a75e03ca.mp4"
                     controls="controls"
                  >
                     您的浏览器不支持 video 标签。
                  </video>
               </div>
            </div>
         </div>
      </el-dialog>
   </div>
</template>
<script>
import { Col, Row, Dialog } from "element-ui";

export default {
   name: "PageFeature2",
   components: {
      [Col.name]: Col,
      [Row.name]: Row,
      [Dialog.name]: Dialog,
   },
   data() {
      return {
         plateVisibile: false,
         plateVisibile2: false,
      };
   },
   methods: {
      handleClose() {
         this.plateVisibile = false;
         this.plateVisibile2 = false;

         const audio1 = document.getElementById("vedio1");
         const audio2 = document.getElementById("vedio2");
         audio1.pause();
         audio2.pause();
      },
   },
};
</script>

<style scoped>
.features {
   max-width: 1200px;
   margin: 0 auto;
   padding-top: 1em;
}
.features h1 {
   color: #d1a861;
   padding: 20px 0;
   font-size: 28px;
   font-weight: 600;
}
.features p {
   text-align: center;
}
img {
   width: 100%;
}
ul {
   text-align: center;
   list-style: none;
}
ul img {
   width: 95%;
}
</style>
