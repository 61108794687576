<template>
   <com-menu-body :menu="menu"></com-menu-body>
</template>

<script>
import { http } from "@/http";
import ComMenuBody from "@/components/MenuBody";

export default {
   name: "PageTobacco",
   components: {
      ComMenuBody,
   },
   data() {
      return {
         menu: [],
      };
   },
   created() {
      if (this.$route.query.node) {
         this.getCategoryTree();
      }
   },
   methods: {
      getCategoryTree() {
         const vm = this;

         http
            .get(`Categorie/tree?alias=${vm.$route.query.node}`, {
               loading: true,
            })
            .then((res) => {
               const menu = [];
               const children = [
                  {
                     label: "全部",
                     path: `?node=${vm.$route.query.node}&type=${res.parent.alias}`,
                  },
               ];
               res.data.forEach((element) => {
                  children.push({
                     label: element.label,
                     path: `?node=${vm.$route.query.node}&type=${element.alias}`,
                  });
               });
               menu[0] = {
                  label: res.parent.title,
                  children,
               };
               this.menu = menu;
            });
      },
   },
};
</script>

<style lang="scss" scoped></style>
