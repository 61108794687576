<template>
   <com-page-main>
      <div class="map-item">
         <div class="map-item__label">
            <span>首</span>
            <span>页</span>
         </div>
         <div class="map-item__inner">
            <router-link
               class="map-item__link"
               :to="{ name: 'lists', query: { type: 'news', node: 'news' } }"
            >
               新闻动态
            </router-link>
            <router-link
               class="map-item__link"
               :to="{
                  name: 'lists',
                  query: { type: 'notice', node: 'notice' },
               }"
            >
               通知公告
            </router-link>
            <router-link class="map-item__link" to="/features">
               专题专栏
            </router-link>
         </div>
      </div>

      <div class="map-item">
         <div class="map-item__label">企业概况</div>
         <div class="map-item__inner">
            <router-link class="map-item__link" to="/company/introduce">
               企业简介
            </router-link>
            <router-link class="map-item__link" to="/company/leader">
               领导班子
            </router-link>
            <router-link class="map-item__link" to="/company/organization">
               组织机构
            </router-link>
            <router-link class="map-item__link" to="/company/develop">
               发展历程
            </router-link>
            <router-link class="map-item__link" to="/company/milestone">
               大事记
            </router-link>
            <router-link class="map-item__link" to="/company/culture">
               企业文化
            </router-link>
         </div>
      </div>

      <div class="map-item">
         <div class="map-item__label">党建动态</div>
         <div class="map-item__inner">
            <router-link class="map-item__link" to="/party">
               党建动态
            </router-link>
         </div>
      </div>

      <div class="map-item">
         <div class="map-item__label">社会责任</div>
         <div class="map-item__inner">
            <router-link class="map-item__link" to="/duty">
               社会责任
            </router-link>
         </div>
      </div>

      <div class="map-item">
         <div class="map-item__label">烟草服务</div>
         <div class="map-item__inner">
            <router-link class="map-item__link" to="/tobacco/classroom">
               真假鉴别
            </router-link>
            <router-link class="map-item__link" to="/tobacco/innovate">
               科技创新
            </router-link>
            <router-link class="map-item__link" to="/tobacco/wenjuan">
               调查征集
            </router-link>
            <router-link class="map-item__link" to="/tobacco/consult">
               投诉咨询
            </router-link>
            <router-link class="map-item__link" to="/tobacco/qiye">
               全级次企业
            </router-link>
         </div>
      </div>
   </com-page-main>
</template>

<script>
import ComPageMain from "@/components/PageMain";

export default {
   name: "PageMap",
   components: {
      ComPageMain,
   },
};
</script>

<style lang="scss" scoped>
.map {
   &-item {
      display: flex;
      align-items: center;
      margin-top: 40px;

      &:first-child {
         margin-top: 0;
      }

      &__label {
         position: relative;
         display: flex;
         justify-content: space-between;
         min-width: 4em;
         font-size: 20px;
         font-weight: 600;
         color: var(--color-main);

         &::after {
            content: "";
            position: absolute;
            left: 100%;
            top: 50%;
            width: 0;
            height: 0;
            margin: -6px 0 0 4px;
            border: 6px solid transparent;
            border-left-color: currentColor;
         }
      }

      &__inner {
         padding: 10px 0;
         margin-left: 40px;
         border-bottom: 1px solid var(--color-main);
      }

      &__link {
         margin-left: 40px;
         font-size: 18px;
         color: var(--color-base);
         text-decoration: none;

         &:hover {
            font-weight: 600;
            color: var(--color-main);
            text-decoration: underline;
         }

         &:first-child {
            margin-left: 0;
         }
      }
   }
}
</style>
