<template>
   <div class="features">
      <h1>专栏专题</h1>
      <ul>
         <li>
            <router-link target="_blank" to="features/75">
               <img src="./imgs/zhuan-ti_01-59oOTiL8.png" alt="" />
            </router-link>
         </li>
         <li>
            <router-link target="_blank" to="features/2">
               <img src="@/assets/imgs/dabanner2.jpg" alt="" />
            </router-link>
         </li>
         <li>
            <a target="_blank" href="/features/tsshzysx">
               <img src="@/assets/imgs/features_02.png" alt="" />
            </a>
         </li>
         <li>
            <a target="_blank" href="/features/foundaparty2021">
               <img src="@/assets/imgs/features_03.png" alt="" />
            </a>
         </li>
         <li>
            <router-link target="_blank" to="zt/chuxin">
               <img src="./imgs/chuxin-banner.jpg" alt="" />
            </router-link>
         </li>
      </ul>
   </div>
</template>

<script>
export default {};
</script>

<style scoped>
.features {
   max-width: 1200px;
   margin: 0 auto;
}
.features h1 {
   color: #d1a861;
   padding: 20px 0;
   font-size: 28px;
   font-weight: 600;
}
ul {
   text-align: center;
   list-style: none;
}
ul img {
   width: 95%;
}
</style>
