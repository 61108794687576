<template>
   <div class="chuxin">
      <div class="chuxin-banner">
         <img src="./imgs/banner.png" alt="" />
      </div>
      <div class="chuxin-index_content">
         <div class="chuxin-container">
            <div class="chuxin-plate_title">
               工作动态
            </div>

            <com-page-main>
               <div class="chuxin-list">
                  <com-new-list
                     ref="new_list"
                     url="post?alias=chuxin"
                     show-date
                     size="medium"
                     pagination
                  ></com-new-list>
               </div>
            </com-page-main>
         </div>
         <div class="copyright white absolute"></div>
      </div>
   </div>
</template>

<script>
import ComNewList from "@/components/NewList";
import ComPageMain from "@/components/PageMain";

export default {
   name: "Chuxin",
   components: {
      ComNewList,
      ComPageMain,
   },
};
</script>

<style lang="scss" scoped>
.container-main {
   padding: 0;
}
.copyright.absolute {
   position: absolute;
   left: 0;
   right: 0;
   bottom: 10px;
}

.copyright.white {
   background-image: url(./imgs/copyright.png);
}
.copyright {
   position: relative;
   z-index: 10;
   height: 100px;
   margin-top: 20px;
   background: url(./imgs/copyright.png) center no-repeat;
}
.chuxin {
   max-width: 1920px;
   margin: 0 auto;
   overflow-x: hidden;
   &-list {
      padding: 15px 25px;
      background-color: #fff;
   }
   &-container {
      position: relative;
      z-index: 10;
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
   }
   &-banner {
      margin: 0;
      overflow: hidden;
   }
   &-banner img {
      display: block;
      width: 100%;
      max-width: 100%;
   }
   &-index_content {
      position: relative;
      padding-bottom: 380px;
      overflow: hidden;
      background: #efdfdf url(./imgs/index_content_bg.jpeg) center 1830px
         no-repeat;
   }
   &-index_content::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 315px;
      background: url(./imgs/index_bottom_bg.jpeg) center no-repeat;
   }
   &-plate_title {
      position: relative;
      height: 65px;
      padding-left: 110px;
      margin: 30px 0 20px;
      line-height: 64px;
      font-size: 22px;
      color: #fff;
      overflow: hidden;
      background: #e6e6e6 url(./imgs/plate_title_bg.jpeg) no-repeat;
      border-radius: 6px;
   }

   &-plate_title .more {
      position: absolute;
      right: 15px;
      top: 0;
      font-size: 16px;
      color: #bd1c21;
   }
}
</style>
